import { template as template_144d52205dbb4f1b8f72917b055f11b5 } from "@ember/template-compiler";
export default template_144d52205dbb4f1b8f72917b055f11b5(`
  <li class='row'>
    <span class='row__label'>
      {{@label}}
    </span>
    <span class='row__value'>
      {{@value}}
    </span>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
